<template>
    <div style="font-size: 1rem;width: 100%">
        <a-row>
            <a-col :span="5"><label>Name:</label></a-col>
            <a-col :span="12"><b>{{ item.firstName }}&nbsp;{{ item.lastName }}</b></a-col>
        </a-row>
        <a-row>
            <a-col :span="5">Status:</a-col>
            <a-col :span="12">
                <b>
                    <div v-if="item.passed" style="color:green">Passed</div>
                    <div v-if="!item.passed" style="color:red">Failed</div>
                </b>
            </a-col>
        </a-row>
        <a-row v-if="item.screen2passId">
            <a-col :span="5">Known User:</a-col>
            <a-col :span="12">
                <b>
                    <div>{{ item.screen2passId }}</div>                    
                </b>
            </a-col>
        </a-row>
        <a-row>
            <a-col :span="5">Taken At:</a-col>
            <a-col :span="12"><b>
                {{
              new Date(item.createdAt) | dateFormat("DD MMM YYYY HH:mm:ss")
            }}
            </b></a-col>
        </a-row>
        <br />
        <a-row v-if="profile.scope === 'GSA' || profile.scope === 'BA'">
            <a-col :span="5">
                <a :href="surveyLink" target="_blank" style="color: #5168EA">
                    <a-icon type="eye"></a-icon>&nbsp;View Survey
                </a>
            </a-col>
        </a-row>
        <br />
        <a-row>
            <a-col :span="24">
                <a-table
            class="nucleus-table"
            :columns="columns"
            :data-source="details"
          >
            <span v-if="text" slot="result" slot-scope="text">
              <a-icon
                type="close-circle"
                theme="filled"
                v-if="!text.correct"
                style="font-size: 1.2rem; color: orangered"
              />
              <a-icon
                type="check-circle"
                theme="filled"
                v-if="text.correct"
                style="font-size: 1.2rem; color: green"
              />
              <!-- {{ text.passed ? 'Passed' : 'Failed' }} -->
            </span>
            </a-table>
            </a-col>
        </a-row>
    </div>
</template>
<script>
import { SurveyResultApi } from "../../services";

let columns = [
    {
      title: "Question",
      dataIndex: "title",
    },
    {
      title: "Given Answer",
      dataIndex: "answer",
    },
    {
      title: "Correct?",
      scopedSlots: { customRender: "result" },
      slots: { title: "result" },
    },
];

export default {
  props: {
    mode: String,
    item: Object
  },
  data() {
      return {
          details: [],
          columns: columns,
          surveyLink: `/survey/${this.item.surveyId}/configure/`,
          profile: JSON.parse(localStorage.getItem("profile") || "{}") || {}
      }
  },
  async mounted() {
    await this.getSurveyResultDetails();
  },
  watch: {
    item() {
      this.getSurveyResultDetails();
    }
  },
  methods: {
      async getSurveyResultDetails() {
          const response = await SurveyResultApi.details(this.item.surveyId, this.item.subscriberId, this.item.attempt);
          this.details = response.data;
          console.log(response.data);
      }
  }
}
</script>
