


























































































































import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
const SurveyResultModule = namespace("SurveyResultModule");
const BusinessModule = namespace("BusinessModule");
const LocationModule = namespace("LocationModule");
import { SurveyResult } from "../../store/modules/surveyresults/types";
import { Business } from "../../store/modules/business/types";
import { Location } from "../../store/modules/location/types";
import Icon from "../../components/Icon.vue";
import SurveyResultDetail from './SurveyResultDetail.vue';
import { sortStringAttributes } from "../../services";

@Component({
  components: {
    Icon,
    SurveyResultDetail
  },
})
export default class SurveyResultList extends Vue {
  @SurveyResultModule.Getter
  public surveyResults: SurveyResult[];

  @BusinessModule.Getter
  public businesses: Business[];

  @LocationModule.Getter
  public locations: Location[];

  public fetching = false;
  public searchTerm = "";
  public profile = JSON.parse(localStorage.getItem("profile") || "{}") || {};
  public visible = false;
  public activeMode = "add";
  public activeItem:any = {};

  public columns = [
    {
      title: "Result",
      scopedSlots: { customRender: "result" },
      slots: { title: "result" },
      sorter: (a, b) => sortStringAttributes(a.result, b.result),
    },
    {
      title: "User Id",
      sorter: (a, b) => sortStringAttributes(a.screen2passId, b.screen2passId),
      slots: { title: "screen2passId" },
      scopedSlots: { customRender: "screen2passId" },
    },
    {
      title: "Full Name",
      scopedSlots: { customRender: "fullName" },
      slots: { title: "fullName" },
      sorter: (a, b) => sortStringAttributes(a.firstName, b.firstName),
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => sortStringAttributes(a.email, b.email),
    },
    {
      title: "Contact No",
      dataIndex: "contactNumber",
      sorter: (a, b) => sortStringAttributes(a.contactNumber, b.contactNumber),
    },
    {
      title: "State",
      dataIndex: "state",
      sorter: (a, b) => sortStringAttributes(a.state, b.state),
    },
    {
      title: "DOB",
      dataIndex: "dob",
      sorter: (a, b) => sortStringAttributes(a.dob, b.dob),
      slots: { title: "dob" },
      key: "dob",
      scopedSlots: { customRender: "dob" },
    },
    {
      title: "Gender",
      dataIndex: "gender",
      sorter: (a, b) => sortStringAttributes(a.gender, b.gender),
    },
    {
      title: "Taken At",
      dataIndex: "createdAt",
      sorter: (a, b) => sortStringAttributes(a.createdAt, b.createdAt),
      slots: { title: "createdAt" },
      key: "createdAt",
      scopedSlots: { customRender: "createdAt" },
    },
    {
      title: "Action",
      slots: { title: "Action" },
      scopedSlots: { customRender: 'Action' },
      fixed: 'right',
      width: 150
    }
  ];

  @SurveyResultModule.Action
  public load!: () => Promise<SurveyResult[]>;

  @SurveyResultModule.Action
  public reset!: () => Promise<void>;

  @BusinessModule.Action
  public loadBusinesses: () => Promise<Business[]>;

  @LocationModule.Action
  public loadAllLocations!: () => Promise<Location[]>;

  @SurveyResultModule.Action
  public search!: (term: string) => Promise<SurveyResult[]>;

  async mounted() {
    this.fetching = true;
    try {
      switch (this.profile.scope) {
        case "GSA":
          this.columns.unshift({
            title: "Location",
            scopedSlots: { customRender: "locationName" },
            slots: { title: "locationName" },
            sorter: (a, b) => sortStringAttributes(a.name, b.name),
          });
          this.columns.unshift({
            title: "Business",
            scopedSlots: { customRender: "businessName" },
            slots: { title: "businessName" },
            sorter: (a, b) => sortStringAttributes(a.name, b.name),
          });
          await this.loadBusinesses();
          await this.loadAllLocations();
          break;
        case "BA":
          this.columns.unshift({
            title: "Location",
            scopedSlots: { customRender: "locationName" },
            slots: { title: "locationName" },
            sorter: (a, b) => sortStringAttributes(a.name, b.name),
          });
          await this.loadAllLocations();
          break;
        default:
          break;
      }
      await this.load();
    } catch (err) {
      console.log(err);
    }
    this.fetching = false;
  }

  getBusinessName(businessId: string) {
    const response = this.businesses.filter(
      (record) => record.businessId === businessId
    );
    if (response.length > 0) return response[0].name;
    return "";
  }

  resultsViewed() {
    this.visible = false;
  }

  getLocationName(locationId: string) {
    const response = this.locations.filter(
      (record) => record.locationId === locationId
    );
    if (response.length > 0) return response[0].name;
    return "";
  }

  async beforeDestroy() {
    this.reset();
  }

  navigate(to) {
    this.$router.push(to);
  }

  onSearch(term) {
    this.searchTerm = term;
    this.search(term);
  }

  customRow(record) {
    return {
      on: {
        click: (event) => {
          this.$router.push(`/location/view/${record.locationId}`);
        },
      },
    };
  }
}
